import request from '@/utils/request'


// 查询用户抽奖记录列表
export function listUserPrize(query) {
  return request({
    url: '/prize/user-prizes/list',
    method: 'get',
    params: query
  })
}

// 查询用户抽奖记录分页
export function pageUserPrize(query) {
  return request({
    url: '/prize/user-prizes/page',
    method: 'get',
    params: query
  })
}

// 查询用户抽奖记录详细
export function getUserPrize(data) {
  return request({
    url: '/prize/user-prizes/detail',
    method: 'get',
    params: data
  })
}

// 新增用户抽奖记录
export function addUserPrize(data) {
  return request({
    url: '/prize/user-prizes/add',
    method: 'post',
    data: data
  })
}

// 修改用户抽奖记录
export function updateUserPrize(data) {
  return request({
    url: '/prize/user-prizes/edit',
    method: 'post',
    data: data
  })
}

// 删除用户抽奖记录
export function delUserPrize(data) {
  return request({
    url: '/prize/user-prizes/delete',
    method: 'post',
    data: data
  })
}
